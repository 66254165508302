/*
    HOME
*/


// Homepage

#home-pg {
    .certificacion-iso {
        border: none;

        .cell {
            max-width: rem-calc(780);
        }

        h2 {
            display: inherit;
        }

        .separator {
            display: inherit;
        }
    }
}

section.novedades {
    padding-top: 0;
}

.novedad-item {

    .novedad-img {
        margin-bottom: 1rem;
    }

    h4 {
        font-size: 1rem;
        font-weight: 600;
        color: black;
        
        a {
            color: black;
            
            &:hover {
                color: $primary-color;
            }
        }
    }

    p {
        font-size: .75rem;
    }
}

section.productos-destacados {
    background: $light-gray;

    h2 {
        font-weight: 400;
        margin-bottom: 2.5rem;
    }
}

.newsletter {
    background: black;

    h3 {
        font-size: 2rem;
        font-weight: 600;
        color: white;
        margin: 0;
    }

    p {
        font-size: 1.375em;
        font-weight: 300;
        color: white;
        margin: 0;
        line-height: 1.2;
    }
    
    .subscription-form-feedback {
        background: black !important;
    }
    .subscription-form-feedback img {
        max-width: 40px !important;
        margin-bottom: .5rem !important;
    }
    
    .subscription-form-feedback p {
        font-size: 1rem !important;
    }
}

section.logos {
    padding: 4rem 0;

    &+footer {
        margin-top: 0;
    }
}

.nl-popup {
    position: fixed;
    z-index: 100;
    bottom: .75rem;
    left: .75rem;
    padding: 1rem;
    width: calc(100% - 1.5rem);
    max-width: rem-calc(460);
    background: #e9e8e8;
    
    
    transition: all .7s ease-out;
    transform: translateY(115%);
    
    .inner {
        position: relative;
        padding-right: 1.5rem;
        
        font-size: .875rem;
        
        p {
            line-height: 1.2;
        }

        h4 {
            font-weight: bold;
            font-size: 1rem;
        }
        
        .button {
            margin: 0;
        }
    }
    
    &.on {
        transform: translate(0);
    }
    
    a.close-popup {
        position: absolute;
        right: 0;
        top: 0;
        color: $dark-gray;
    }
}