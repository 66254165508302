/*
    PRODUCTOS
*/

.no-js {
    .products-filters ul ul {
        display: none;
    }
}


.products-filters {

    @include breakpoint(medium) {
        flex-basis: 33% !important;
    }

    @include breakpoint(large) {
        flex-basis: 20% !important;
    }

    .filters-wrapper {
        max-height: 110px;
        overflow-y: auto;
        padding: .5rem 0 .5rem .75rem;
        border: 1px solid #dcdcdc;
        margin-bottom: 1rem;
    }

    .products-filters-title {
        padding: .75rem 0;
        border-bottom: 2px solid $primary-color;
        color: $primary-color;
        font-size: rem-calc(13);
        font-family: $montserrat;
        font-weight: 600;
    }

    .products-filters-subtitle {
        font-size: rem-calc(13);
        font-family: $montserrat;
        font-weight: 600;
        color: black;
        margin-bottom: .25rem;
    }

    ul {
        margin-bottom: 0;
    }

    li {
        padding: 0;
        font-size: .75rem;
        line-height: 1.25;
        color: #333;
        font-family: $montserrat;
        font-weight: 500;

        li {
            padding-left: .5rem;
        }

        .filter {
            display: flex;
            align-items: flex-start;

            span {
                margin-top: 5px;
            }
        }



    }


}
/*
.products-filters {
    @include xy-cell(20%);
}

.products-list {
    @include xy-cell(80%);
}
*/
.producto-item {

    .producto-item-img {
        margin-bottom: .5rem;
        background: white;
        border: 1px solid $medium-gray;

        a {
            display: block;
            position: relative;
            overflow: hidden;

            img {
                transition: all .3s $easeOut;
            }

            .overlay {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 2rem;
                background: rgba(0,0,0,.5);
                opacity: 0;
                transition: all .3s ease-out;

                i {
                    color: black;
                    transition: all .3s $easeOut;
                    transform: translate(0,-50px);

                    &:hover {
                        color: white;
                    }
                }
            }

            &:hover {

                img {
                    transform: scale(1.25);

                }
                .overlay {
                    opacity: 1;

                    i {
                        transform: translate(0,0);

                    }
                }
            }
        }

    }

    h4 {
        font-size: 1rem;
        font-weight: 600;
        color: black;
        margin-bottom: .125rem;
    }

    p {
        line-height: 1.2;
    }
}

.product-gallery {
    border: 1px solid $medium-gray;
}

#product-gallery {
    width: 100%;
    height: 100%;

    .swiper-slide {
        width: 100%;
        height: 100%;
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.product-main-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint(small only) {
        margin-top: 1rem;
    }

    .product-title {
        h3 {
            color: $primary-color;
            font-weight: 700;

            @include breakpoint(large) {
                font-size: 2.75rem;
            }
        }
        h4 {
            color: $primary-color;
            font-weight: 400;

            @include breakpoint(large) {
                font-size: 1.75rem;
            }
        }

        p.marcas {
            margin-bottom: .5rem;
            &+h3 {
                margin-top: 0;
            }
        }
    }

}

.product-gallery-thumbnails {
    display: flex;
    flex-direction: row;
    //justify-content: space-between;

    .product-thumbnail {
        border: 1px solid $medium-gray;
        margin: 0 .25rem;
        flex-basis: calc(33.3% - .5rem);



    }
}

.productos-search {
    display: inline-block;
    margin: 0;
}

.section-article {
    color: $black;
    border-bottom: 1px solid $medium-gray;

    @include breakpoint(medium) {
        padding-top: 1.5rem;
    }

    @include breakpoint(large) {
        padding-top: 2rem;
    }

    .section-article-title {
        background-color: $primary-color;
        padding: .75rem 0;

        @include breakpoint(medium) {
            margin-bottom: 1.5rem;
            background-color: $white;
            padding: 0;
        }

        h4 {
            color: white;
            font-weight: 600;
            margin: 0;

            @include breakpoint(medium) {

                font-size: 2rem;
                color: $primary-color;
            }
        }

    }

    .section-article-content {
        padding: 1rem 0 2rem;

        @include breakpoint(medium) {
            padding: 1.5rem 0 2.75rem;
        }

        @include breakpoint(large) {
            padding: 1.5rem 0 4rem;
        }

        h5 {
            color: $secondary-color;
            font-weight: 400;
            margin-bottom: 1rem;
            font-size: 1.125rem;

            @include breakpoint(medium) {

                font-size: 1.75rem;
                margin-bottom: 1.5rem;
            }
        }
    }

    table {
        margin: 0;
        height: auto !important;

        @include breakpoint(small only) {
            @include table-stack(true);
            padding-bottom: 0;
        }

        tr {
            height: auto !important;
        }

        th {
            text-align: center;
            background: #f2f2f2;
            width: auto !important;
            height: auto !important;
        }

        td {
            vertical-align: top;
            width: auto !important;
            height: auto !important;

            &:first-child {

                font-weight: 700;
                //border-bottom: 1px solid $medium-gray;
                @include breakpoint(small only) {

                    padding-bottom: 0;
                }

                @include breakpoint(medium) {
                    width: 22%;
                    border-bottom: 0;
                    border-right: 1px solid $medium-gray;
                }
            }
        }
    }

    &:last-child {
        border: none;
    }
}

// Recursos
.recurso-item {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;

    .recurso-thum {
        margin-right: .5rem;

        img {
            max-width: 100px;
        }

    }
    .recurso-content {
        .recurso-nombre {
            font-weight: bold;
            margin-bottom: 1rem;
            @include clearfix();

            img {
                margin-right: .5rem;
                float: left;
            }

            span {
                float: left;
                max-width: calc(100% - 4.5rem);
                overflow: hidden;
                padding-top: 1rem;
            }

        }
        .button {
            margin-bottom: 0;
        }
    }


}
