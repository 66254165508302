/*
    CONTACTO
*/


.contact-wrapper {
    background: $light-gray;
    padding: 1.75rem;
    
    p {
        color: black;
        line-height: 1.3;
    }
}

.map-wrapper {
    width: 100%;
    height: rem-calc(250);
    
    @include breakpoint(medium) {
        height: rem-calc(300);
    }

    @include breakpoint(large) {
        height: 27.25rem;
    }
    
    iframe {
        width: 100%;
        height: 100%;
    }
}