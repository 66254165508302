/*
    WHATSAPP
*/

.whatsapp-widget {
    position: fixed;
    z-index: 9999;
    right: 1rem;
    bottom: 1rem;
    
    display: flex;
    align-items: center;
    
    transform: translateY(50px);
    opacity: 0;
    transition: all .5s ease-out;
    
    &.on {
        transform: translate(0);
        opacity: 1;
    }
    
    .whatsapp-icon {
        width: 4rem;
        margin-left: 1rem;
    }
    
    .whatsapp-labels {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        
        a {
            display: inline-block;
            padding: .25rem .5rem;
            font-size: .75rem;
            color: white;
            background: #333;
            border-radius: .25rem;
            margin-bottom: .25rem;
            transition: all .3s ease-out;
            position: relative;
            
            &:hover {
                background: #25D366;
            }
            /*
            &:after {
                content: '';
                border: .5rem solid transparent;
                border-left-color: #333;
                position: absolute;
                right: -15px;
                top: 4px;
            }
            */
        }
    }
}