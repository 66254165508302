/*
    NOTISIAFA
*/


.first-noti-wrapper {
    background: $light-gray;
    padding: 1.75rem 0;
    margin: 1.75rem 0;
    
    h3 {
        @include breakpoint(large) {
            font-size: 1.75rem;
            color: $primary-color;
            font-weight: 400;
        }
    }
    
}

.otros-noti {
    h3 {
        @include breakpoint(large) {
            font-size: 1.75rem;
            color: $primary-color;
            font-weight: 700;
        }
    }
}
