/*
    SERVICES
*/


#clientes-slider {

    .swiper-slide {
        padding-bottom: 2rem;
    }

    .swiper-pagination {
        bottom: 0;

        .swiper-pagination-bullet {
            background-color: $medium-gray;
            opacity: .5;
            width: 1rem;
            height: 1rem;
            margin: 0 .375rem;

            &.swiper-pagination-bullet-active {
                opacity: 1;
            }
        }
    }
}

.servicios-page {
    .section-article-title {
        h4 {
            color: $secondary-color;
        }
    }
}

.servicios-intro {
    font-weight: 600;
    color: black;
    padding: 1rem 0;
    
    @include breakpoint(medium) {
        padding-top: 2rem;
        font-size: 1.25rem;
       
    }
}

.servicios-options {
    
    
    > .grid-container {
        @include breakpoint(medium down) {
            padding: 0;
        }
    }
    
    @include breakpoint(medium) {
        border-top: 1px solid $medium-gray;
        border-bottom: 1px solid $medium-gray;
        margin-bottom: 4rem;
        
        .servicios-img {
            height: 100%;
            
            img {
                width: 100%;
                height: 100%;
                max-width: none;
                object-fit: cover;
            }
        }
    }
}

.servicios-list {
    padding: 2rem;
    
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        
        li {
            position: relative;
            margin-bottom: 2.25rem;
            text-align: center;
            
            a {
                color: black;
                font-size: .875rem;
                
                p.tit {
                    font-size: 1.25rem;
                    font-weight: 600;
                    margin-bottom: .5em;
                    
                    &+p {
                        display: none;
                    }
                }
                
                img {
                    display: inline-block;
                    width: 4.5rem;
                    margin: 0 auto .75rem;
                    background: $primary-color;
                    border-radius: 50%;
                    padding: .75rem;
                }
            }
        }
    }
    
    @include breakpoint(medium) {
        padding: 2rem 1.25rem;
        ul {

            li {
                position: relative;
                margin-bottom: 2.25rem;
                padding-left: 4rem;
                text-align: left;
                
                a {
                    color: black;
                    font-size: .875rem;

                    p.tit {
                        font-size: 1rem;
                        line-height: 1.25;
                        font-weight: 600;
                        
                        &+p {
                            display: block;
                        }
                    }

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 3.5rem;
                        background: $primary-color;
                        border-radius: 50%;
                        
                        margin: auto;
                    }
                }
            }
        }
    }
    
    @include breakpoint(large) {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 2rem 1.5rem 2rem 3rem;
        
        ul {

            li {
                padding-left: 0;
                margin-bottom: 3rem;
                a {
                    padding-left: 5.75rem;
                    display: block;
                    position: relative;
                    transition: all .3s ease-out;
                    p {
                        font-size: 1rem;
                    }
                    p.tit {
                        font-size: 1.25rem;
                    }

                    img {
                        width: auto;
                    }
                    
                    
                }
                
                &:hover {
                    a {
                        transform: translateX(2rem);
                    }
                }
            }
        }
    }
}