/*
    HEADER
*/

// Prevent FOUC

.no-js {
    @include breakpoint(medium down) {
        .top-bar {
            display: none;
        }
    }

    @include breakpoint(large) {
        .title-bar {
            display: none;
        }
    }
}

/**********/

// Top Opts

.top-opts-bar {
    border-bottom: 1px solid $medium-gray;

    ul.top-opts {
        list-style: none;
        margin: 0;
        padding: 0 .25rem 0 0;
        color: $medium-gray;
        font-size: .75rem;
        border-right: 1px solid $medium-gray;
        display: inline-flex;

        li {
            padding: .5rem;

            a {
                display: inline-block;
                color: $medium-gray;

                &:hover {
                    color: $primary-color;
                }
            }

            &:first-child {
                margin-right: .25rem;
            }
        }
    }

    ul.social {
        list-style: none;
        margin: 0;
        padding: 0;
        color: $medium-gray;
        font-size: .75rem;
        display: inline-flex;

        li {

            border-left: 1px solid $medium-gray;

            a {
                color: $medium-gray;
                padding: .5rem 1rem;
                display: block;

                &:hover {

                    color: white;
                }

                &.facebook:hover {
                    background-color: #0069c6;
                }

                &.linkedin:hover {
                    background-color: #0e76a8;

                }
                &.instagram:hover {
                    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
                }
                &.youtube:hover {
                    background-color: #FF0000;

                }
            }
        }
    }
}

/**********/

// Header

header {
    position: relative;
    z-index: 5;
    border-bottom: 1px solid $medium-gray;

    >.grid-container {
        padding: 0;
    }

    .header-inner {
        @include breakpoint(large) {
            @include xy-grid-container();
        }
    }

    h1.logo {
        position: absolute;
        left: 1rem;
        margin: 1.125rem 0;
        transition: all .3s ease-out;

        @include breakpoint(large) {
            left: auto;
        }

        a {
            width: 186px;
            height: 40px;
            display: block;
            background: url('/img/siafa-logo-small.svg') center center / cover;


            @include breakpoint(large) {
                background-image: url('/img/siafa-logo.svg');
                width: 230px;
                height: 60px;

            }
        }
    }
}


/**********/

// Nav

.title-bar {
    min-height: rem-calc(76);
    justify-content: flex-end;
}

.top-bar {
    @include breakpoint(medium down) {
        padding: 0;
    }
}

ul.main-menu {
    li {

        a {
            font-family: $montserrat;
            font-weight: 500;
            color: black;
            transition: none;

            &:hover,
            &.active {
                color: $primary-color;
            }
        }

    }

    >li {
        @include breakpoint(medium down) {
            border-bottom: 1px solid $light-gray;
        }
    }

    &.accordion-menu {

        .is-accordion-submenu {
            margin-bottom: 1rem;

            a {
                padding: .75rem 1.25rem;
                font-size: .9em;
                color: #333;

                &:hover,
                &.active {
                    color: $primary-color;
                }
            }
        }

        li[aria-expanded='true'] > a {

            &::after {
                transform: rotate(-135deg);
                margin-top: -.25rem;
            }
        }
    }
}

.is-dropdown-submenu {
    border-width: 3px 0 0 0;
    background: white !important;

    li {
        border-bottom: 1px solid $light-gray;

        a {
            white-space: nowrap;
            font-size: .875rem;
        }
    }
}


// Dropdown arrow

.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle)>a::after {
    display: block;
    width: 1rem;
    height: 1rem;
    border: 1px solid $dark-gray;
    border-top: 0;
    border-left: 0;
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -.75rem;
    right: 1rem;
    transform: rotate(45deg);
}
