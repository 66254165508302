/*
    SLIDER
*/


.home-slider-wrapper {

}

#home-slider {
    width: 100%;
    height: rem-calc(480);
    margin: 0 auto;

    @include breakpoint(medium) {
        height: rem-calc(750);
    }

    @include breakpoint(large) {
        height: rem-calc(420);
        margin: 0 auto 2rem;
    }

    .swiper-slide {
        background-position: center top;
        background-size: auto 280px;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        @include breakpoint(medium) {
            background-position: center top;
            background-size: auto 420px;
        }

        @include breakpoint(large) {
            background-position: center center;
            background-size: cover;
        }

        .slide-inner {
            display: none;

            @include breakpoint(large) {
                display: flex;
            }

            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            text-align: right;
            width: 100%;
            max-width: $global-width;
            padding: 0 (map-safe-get($grid-container-padding, medium)/2);
            height: 100%;
            box-sizing: border-box;

            .slide-logo {
                margin-bottom: 1rem;

                // OFF
                opacity: 0;
                transform: translate(0, 50px);
                transition: all .75s 1s cubic-bezier(.66,.09,.41,.99);
            }

            h2 {
                font-weight: 700;
                font-size: 2.125rem;
                margin: 0 0 .75rem;

                // OFF
                opacity: 0;
                transform: translate(0, 50px);
                transition: all .75s 1.3s cubic-bezier(.66,.09,.41,.99);
            }

            h3 {
                color: black;
                font-weight: 300;
                font-size: 1.25rem;
                margin: 0 0 1.5rem;

                // OFF
                opacity: 0;
                transform: translate(0, 50px);
                transition: all .75s 1.6s cubic-bezier(.66,.09,.41,.99);
            }

            .button {

                // OFF
                opacity: 0;
                transform: translate(0, 50px);
                transition: all .75s 1.9s cubic-bezier(.66,.09,.41,.99);

                &.hollow {
                    background: transparent;
                    color: white;
                    border-color: white;
                    border-radius: 0;
                    font-weight: 300;
                }
            }




            @include breakpoint(medium) {

            }

            @include breakpoint(large) {

            }

        }
/*
        &#slide1,
        &#slide2,
        &#slide3, {
            background-image: url('/content-temp/slide-small.jpg');

            @include breakpoint(large) {
                background-image: url('/content-temp/slide.jpg');
            }

        }
*/
        &.swiper-slide-active {
            .slide-inner {
                h2, h3, .button.hollow, .slide-logo {
                    opacity: 1;
                    transform: translate(0, 0);
                }
            }
        }
    }

    .swiper-pagination-bullet {
        background-color: $light-gray;
        opacity: .5;
        width: 1rem;
        height: 1rem;
        margin: 0 .375rem;

        &.swiper-pagination-bullet-active {
            opacity: 1;
        }
    }
}


.slide-small-footer {
    padding: 1rem (map-safe-get($grid-container-padding, small)/2);
    width: 100%;
    height: rem-calc(200);
    box-sizing: border-box;

    @include breakpoint(medium) {
        height: rem-calc(330);
    }

    img {
        max-width: rem-calc(200);
        margin-bottom: .75rem;
    }
    .slide-title {

        h2 {
            font-weight: 700;
            font-size: 1rem;
            line-height: 1.1;
            margin-bottom: 1rem;
        }
    }

    .slide-subtitle {

        h3 {
            font-weight: 300;
            line-height: 1.1;
            margin-top: 0;
        }
    }

    .button {

        &.hollow {
            background: transparent;
            color: black;
            border-color: black;
            border-radius: 0;
            font-weight: 400;
        }
    }

    @include breakpoint(medium) {
        padding: 1rem (map-safe-get($grid-container-padding, medium)/2);

        img {
            max-width: rem-calc(300);
        }

        .slide-title {
            h2 {
                font-size: 1.875rem;
                margin-bottom: 1rem;
            }
        }
        .slide-subtitle {
            h3 {
                font-size: 1.25rem;
                margin-bottom: 1rem;
            }
        }
    }
}





.slider-button-prev,
.slider-button-next {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: -1rem;
    position: absolute;
    top: 50%;
    z-index: 10;
    cursor: pointer;

    @include breakpoint(medium) {
        width: 2rem;
        height: 2rem;
        margin-top: -1.5rem;
    }

    @include breakpoint(large) {
        width: 2.5rem;
        height: 2.5rem;
        margin-top: -2rem;
    }

    &.swiper-button-disabled {
        opacity: .4;
    }
}

.slider-button-prev {
    background: url('/img/slider-arrow-left.svg') center center / cover no-repeat;
    left: 1rem;
}

.slider-button-next {
    background: url('/img/slider-arrow-right.svg') center center / cover no-repeat;
    right: 1rem;
}

.swiper-pagination-bullet {
    background-color: $medium-gray;
    opacity: 1;

    &.swiper-pagination-bullet-active {
        background-color: $dark-gray;
    }
}
