/*
    FONTS
*/

//
// GOOGLE WEBFONTS
//
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600|Open+Sans:300,400,600,700&display=swap');

//$crimson: "Crimson Text", Times, "Times New Roman", serif;
//
// LOCAL WEBFONTS
//
//@import url('/fonts/typicons/typicons.min.css');

@import "../fonts/fontawesome-pro-5.7.2/css/brands.min.css";
@import "../fonts/fontawesome-pro-5.7.2/css/light.min.css";
@import "../fonts/fontawesome-pro-5.7.2/css/solid.min.css";
@import "../fonts/fontawesome-pro-5.7.2/css/fontawesome.min.css";

/*
@font-face {
    font-family: 'NeueHaasUnicaPro-Light';
    src: url('../fonts/2E39A8_2_0.eot');
    src: url('../fonts/2E39A8_2_0.eot?#iefix') format('embedded-opentype'),
        url('..//fonts/2E39A8_2_0.woff2') format('woff2'),
        url('..//fonts/2E39A8_2_0.woff') format('woff'),
        url('..//fonts/2E39A8_2_0.ttf') format('truetype');
}
*/

$montserrat: 'Montserrat', Arial, sans-serif;
$opensans: 'Open Sans', Arial, sans-serif;
