/*
    FOOTER
*/



footer {
    padding: 2rem 0;
    background: $light-gray;
    border-top: 1px solid $medium-gray;
    font-size: .75rem;
    line-height: 1.2;
    margin-top: 4.5rem;
    
    @include breakpoint(small only) {
        padding-bottom: 8rem;
    }

    .footer-col {
        margin-bottom: 2rem;
    }

    h4 {
        font-family: $montserrat;
        font-size: 1.25rem;
        margin-bottom: 1rem;
        font-weight: 500;
        color: black;

        @include breakpoint(medium) {
            margin-bottom: 2rem;
        }
    }

    .logo {
        margin-bottom: 2rem;
    }

    ul.contacto {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            position: relative;
            padding-left: 2rem;
            margin-bottom: 1.5rem;

            a {
                color: $dark-gray;

                &:hover {
                    color: $primary-color;
                }
            }

            i {
                position: absolute;
                top: .25rem;
                left: 0;
            }
        }
    }

    ul.social {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            vertical-align: top;
            margin-right: .5rem;

            a {
                color: $dark-gray;



                &.facebook:hover {
                    color: #0069c6;
                }

                &.linkedin:hover {
                    color: #0e76a8;

                }
                &.instagram:hover {
                    //background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
                    color: #dc2743;

                    //background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
                    //-webkit-background-clip: text;
                    //-webkit-text-fill-color: transparent;
                }
                
                &.youtube:hover {
                    color: #FF0000;

                }
            }
        }
    }

    .bottom-bar {
        margin-top: 1rem;

        p {
            margin: 0;
        }
    }
}
