/*
    RECURSOS
*/

.recurso {
    @include breakpoint(large) {
        margin-right: 2rem;
    }

    .recurso-item .recurso-content .recurso-nombre span {
        max-width: none;
    }
}

.recurso-header {
    border-bottom: 1px solid $medium-gray;
    margin-bottom: 1rem;

    h2 {
        color: $primary-color;
        font-weight: 700;
        margin: 0 0 .75rem 0;
        line-height: 1.1;

        @include breakpoint(medium) {
            font-size: 2rem;
        }

        @include breakpoint(large) {
            font-size: 2.5rem;
        }
    }

    h3 {
        font-weight: 600;
        font-size: 1.125rem;
        color: black;
        margin: 0 0 .75rem 0;
    }
}

.social-share {
    margin-bottom: 1rem;

}

.recurso-content {
    margin-bottom: 1.5rem;

    @include breakpoint(medium) {
        margin-bottom: 3rem;
    }

    h3 {
        font-weight: 700;
        font-size: 1.375rem;
        color: black;
        margin: 0 0 .75rem 0;

        @include breakpoint(medium) {
            font-size: 1.75rem;
            margin: 1.5rem 0 1.25rem;
        }
    }

    h4 {
        font-weight: 700;
        font-size: 1.125rem;
        color: black;
        margin: 0 0 .75rem 0;

        @include breakpoint(medium) {
            font-size: 1.25rem;
            margin: 1.125rem 0 1rem;
        }
    }
}

// Ultimas notas
.last-notas {
    margin: 2rem 0;
    
    h4 {
        color: white;
        font-weight: 700;
        background: $secondary-color;
        padding: .625rem .625rem .5rem;
    }
    
    li {
        padding: .375rem .625rem;
        border-bottom: 1px solid $medium-gray;
        
        a {
            font-weight: 600;
            line-height: 1.2;
            
            @include breakpoint(medium) {
                font-size: 1.125rem;
                color: black;
            }
            
            &:hover {
                color: $primary-color;
            }
        }
    }
}

.recurso-footer {
    border: 1px solid $medium-gray;
    border-width: 1px 0;
    padding: 1rem 0 .5rem;
    margin: 1.5rem 0 1.5rem;
}

.recurso-disclaimer {
    padding: 1rem 1rem .1rem 1rem;

    background-color: $light-gray;
    color: black;

    p {
        line-height: 1.2;
    }
}
