/*
    GLOBAL
*/

body {
    //padding-top: 4.875rem;        // Space for fixed header
    //padding-bottom: 4.25rem;       // Space for fixed footer
    //min-height: 100%;

}

h2.larger {
    font-size: 2.25rem;
}

h3 {
    font-weight: 600;
    color: $secondary-color;
}

a {
    transition: all .3s ease;
}

.fw-light { font-weight: 300; }
.fw-regular { font-weight: 400; }
.fw-medium { font-weight: 500; }
.fw-semibold { font-weight: 600; }

section {
    padding: 1rem 0;

    @include breakpoint(medium) {
        padding: 2rem 0;
    }

    &.main {
        @include breakpoint(large) {
            padding-top: 3rem;
        }
    }

    &.section-hero {
        background-color: #f8f8f8;
        border-bottom: 1px solid $medium-gray;

        .hero-img {
            @include breakpoint(small only) {
                margin-bottom: 2rem;
            }
        }
    }

}

a.button,
button.button {
    border-radius: 5px;
}

a.button:not(.hollow),
button.button:not(.hollow) {
    font-weight: 700;

    background-image: linear-gradient(to bottom, #72af2b 30%, #369933 80%);
    background-size: 100% 90px;
    background-position: 0 -30px;
    transition: all .15s ease-out;
    text-transform: uppercase;

    i {
        line-height: 19px;
        vertical-align: text-bottom;
    }

    i + span,
    span + i {
        padding-left: .75rem;
        margin-left: .75rem;
        border-left: 1px solid #a0d850;
        box-shadow: -1px 0 0 #45680e;
    }

    &:hover {
        background-position: 0 0;
    }

    &.secondary {
        background-image: linear-gradient(to bottom, #f8b700 30%, #f88f00 80%);
        color: white !important;

        i + span,
        span + i {
            border-left: 1px solid #fbd773;
            box-shadow: -1px 0 0 #946d00;

        }
    }
}

.mb1 {
    margin-bottom: 1rem;
}

.mb2 {
    margin-bottom: 2rem;
}

.primary-bgr {
    background-color: $primary-color;
    color: white;
}
.secondary-bgr {
    background-color: $secondary-color;
    color: white;
}

.separator {
    height: 1px;
    background-image: linear-gradient(to right, rgba(153,153,153,0), rgba(153,153,153,1) 50%, rgba(153,153,153,0) 100%);
    margin: 2rem 0;
}

$easeOut: cubic-bezier(.22,.63,.47,.99);

.section-header {
    background-color: $light-gray;

    padding: .75rem 0;

    @include breakpoint(medium) {
        padding: 1rem 0;
    }

    @include breakpoint(large) {
        padding: 1.5rem 0;
    }

    h2 {
        margin: 0;
    }
}

// Destacado
.destacado {
    text-align: center;

    .destacado-header {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
            margin-right: 1rem;
            font-size: 2em;
        }

        @include breakpoint(medium) {
            padding: 1.5rem 2rem;

            i {
                font-size: 3em;
            }
        }
    }

    .destacado-body {
        padding: 1rem;

        @include breakpoint(medium) {
            padding: 1.5rem 2rem;
        }
    }
}

// Links lists

ul.links-list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        margin: 0 0 .125rem 0;

        a {
            padding: .375rem 1rem .375rem 1.25rem;
            border-left: 6px solid $medium-gray;
            color: $body-font-color;
            display: block;

            &:hover {
                color: $primary-color;
                border-color: $primary-color;
            }
        }
    }
}


// Search

.search-form {
    max-width: rem-calc(360);
    margin: 1rem 0;
}

//
// FIXED SECTION NAV
//
.section-menu-wrapper{

    &.fixed {

        .section-menu {
            position: fixed;
            width: 100%;
            z-index: 2;
        }

    }
}

.section-menu {
    background-color: $primary-color;
    box-shadow: 0 3px 5px rgba(0,0,0,.25);

    ul {

        li {

            a {
                color: white;
                font-weight: 600;
                padding: 1rem;

                @include breakpoint(large) {
                    font-size: 1.25rem;
                    padding: 1.5rem 1rem;
                }
            }
        }
    }
}

// Breadcrumbs

.breadcrumb-wrapper {
    border-bottom: 1px solid $medium-gray;
    padding: .75rem 0;

    .breadcrumbs {
        margin: 0;

        li {
            font-family: $montserrat;
            font-size: .75rem;
            font-weight: 600;
            text-transform: none;
            color: $medium-gray;

            i {
                margin-right: .375rem;
            }

            a {
                color: $medium-gray;

                &:hover {
                    color: $primary-color;
                }
            }
        }
    }
}

// Accordion

.accordion-title {
    padding: 1.5rem 1rem 1.5rem 4rem;

    &::before {
        position: absolute;
        top: 50%;
        right: auto;
        left: 0;
        margin-top: -1.5rem;
        content: '\f067';

        font-family: "Font Awesome 5 Pro";
        font-weight: 700;
        font-size: 1.25rem;
        color: $medium-gray;
        line-height: 3rem;
        text-align: center;

        width: 3rem;
        height: 3rem;
        border: 1px solid $medium-gray;
    }
}

.is-active {

    &>.accordion-title {
        color: $primary-color;

        &::before {
            content: '\f068';
            border-color: $primary-color;
            background-color: $primary-color;
            color: white;
        }
    }
}

// Pagination
.pagination li {
    margin: .25rem .125rem;
}

.pagination a, .pagination button, .pagination .current {
    font-family: $montserrat;
    font-weight: 600;
    height: 2.5rem;
    line-height: 2rem;
}

.pagination a.num, .pagination .current {
    border: 1px solid black;
    width: 2.5rem;
}

// Go Back
.go-back {
    padding: 1.5rem 0 0;
    margin-bottom: -1rem;

    a {
        font-family: $montserrat;
        font-weight: 500;
        color: $black;
        font-size: .875rem;

        i {
            background: $medium-gray;
            width: 2rem;
            height: 2rem;
            line-height: 2rem;
            text-align: center;
            margin-right: .5rem;
            z-index: 1000;
            border-radius: 50%;
        }

        &:hover {
            color: $primary-color;

            i {
                color: inherit;
            }
        }
    }
}

// ISO

.certificacion-iso {
    text-align: center;

    padding: 0 1rem;
    margin-bottom: 1.75rem;

    h2 {
        display: none;
    }

    @include breakpoint(medium down) {
        padding-top: 0;
    }

    .logo-iso {
        margin-bottom: 2rem;
    }
}

.right-column {
    .certificacion-iso {
        border: 1px solid $medium-gray;
        padding: 2rem 1rem 1rem;

        .separator {
            display: none;
        }
    }
}

//
// GO TO TOP button
// Positioned fixed or absolute depending con scroll position & footer height
//
footer {
    position: relative;
}
.go-to-top {
    position: fixed;
    right: 2rem;
    bottom: 8rem;
    visibility: visible;
    opacity: .9;
    transition: all .3s ease-out;

    a {
        font-family: $montserrat;
        font-weight: 500;
        color: $black;

        i {
            background: $medium-gray;
            width: 2rem;
            height: 2rem;
            line-height: 2rem;
            text-align: center;
            margin-right: .5rem;
            z-index: 1000;
            border-radius: 50%;
        }
    }

    &.off {
        opacity: 0;
        visibility: hidden;
    }

    &.fixed {
        position: absolute;
        bottom: auto;
        top: -50px;
    }
}

//
// FIXED HEADER
//
.top-fixed-content {
    position: fixed;
    width: 100%;
    background: white;
    z-index: 1000;
}
