/*
    FORMS
*/

.consultas-form {
    max-width: rem-calc(600);
}

form h4 {
    margin-bottom: 2rem;
}

textarea {
    height: rem-calc(150);
}

// Checkbox Check
.checkbox-check {
    display: inline-block;
    vertical-align: middle;
    margin: .25rem 0;

    &+.checkbox-check {
        margin-left: 1rem;
    }

    input.chk {
        display: none;

        &+label {
            position: relative;
            display: inherit;
            vertical-align: top;
            width: 1rem;
            height: 1rem;
            border-radius: 3px;
            border: 1px solid $medium-gray;
            transition: all .25s ease-out;
            margin: 0 .5rem 0 0;
        }

        &:checked + label {
            border-color: $primary-color;

            &:after {
                position: absolute;
                top: -.125rem;
                left: .125rem;
                width: 1rem;
                height: 1rem;
                text-align: center;
                line-height: 1rem;
                content: '\f00c';
                font-family: "Font Awesome 5 Pro";
                font-size: 1.25rem;
                font-weight: 300;
                color: $primary-color;
                z-index: 1;

            }
        }
    }
}
