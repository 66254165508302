/*
    MARQUEE
*/


.marquee-wrapper {
    width: 100%;
    overflow: hidden;
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;

    &.isPlaying {
        justify-content: flex-start;
    }

    .marquee {
        display: inline-flex;
        flex-wrap: nowrap;

        flex-shrink: 0;

        .logo {

            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex: 1 0 auto;

            height: 100px;
            margin: 0 1rem;

            @include breakpoint(large) {
                height: 140px;
                margin: 0 1.5rem;
            }

            img {
                height: 100%;
                width: auto;
            }

        }
    }

    // Si .marquee-wrapper tiene la clase .show-medium-as-grid, en pantallas medianas y grandes se mostrará una grilla
    // en lugar del marquee.

    &.show-medium-as-grid {

        @include breakpoint(medium) {
            .marquee {
                display: flex;
                flex-shrink: 1;
                flex-wrap: wrap;

                a {
                    margin: 0 .25rem;
                }
            }
        }
    }
}
