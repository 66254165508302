/*
    SIDE MENU
*/

.side-menu {
    border: 1px solid $medium-gray;
    margin-bottom: 1rem;
    padding: .5rem 1.5rem;
    
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        
        li {
            padding: .75rem .5rem .75rem 0;
            border-bottom: 1px solid $light-gray;
            font-family: $montserrat;
            
            &.tit {
                font-weight: 500;
            }
            
            color: black;
            
            &:first-child,
            &:last-child {
                border: 0;
            }
            
            a {
                color: black;
                
                &:hover {
                    color: $primary-color;
                }
                
                &.active {
                    color: $primary-color;
                    font-weight: 500;
                }
            }
        }
    }
}
